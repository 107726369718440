import { render, staticRenderFns } from "./ServiceEdit.vue?vue&type=template&id=50e976ca&scoped=true&"
import script from "./ServiceEdit.vue?vue&type=script&lang=js&"
export * from "./ServiceEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e976ca",
  null
  
)

export default component.exports