<template>
  <div class="boards-container">
    <div
      class="ym-toptitle boards-container__board status-10"
      :style="{ 'border-top': `7px solid ${getStatus(10)?.colour}` }"
    >
      <h3 class="title">{{ getStatus(10)?.name }}</h3>
      <draggable
        class="list-group"
        data-status-id="10"
        :list="orders10"
        group="orders"
        :sort="false"
        @end="checkMove"
      >
        <OrdersKanbanItem
          v-for="order in orders10"
          :key="order.id"
          :order="order"
        />
      </draggable>
    </div>
    <div
      class="ym-toptitle boards-container__board status-30"
      :style="{ 'border-top': `7px solid ${getStatus(30)?.colour}` }"
    >
      <h3 class="title">{{ getStatus(30)?.name }}</h3>
      <draggable
        class="list-group"
        data-status-id="30"
        :list="orders30"
        group="orders"
        :sort="false"
        @end="checkMove"
      >
        <OrdersKanbanItem
          v-for="order in orders30"
          :key="order.id"
          :order="order"
        />
      </draggable>
    </div>
    <div
      class="ym-toptitle boards-container__board status-40"
      :style="{ 'border-top': `7px solid ${getStatus(40)?.colour}` }"
    >
      <h3 class="title">{{ getStatus(40)?.name }}</h3>
      <draggable
        class="list-group"
        data-status-id="40"
        :list="orders40"
        group="orders"
        :sort="false"
        @end="checkMove"
      >
        <OrdersKanbanItem
          v-for="order in orders40"
          :key="order.id"
          :order="order"
        />
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrdersKanbanItem from "./OrdersKanbanItem.vue";
import draggable from "vuedraggable";

export default {
  name: "OrdersKanbanView",
  props: {
    orders: {
      type: Map,
      required: true,
    },
  },
  components: {
    OrdersKanbanItem,
    draggable,
  },
  data() {
    return {
      orders10: [],
      orders30: [],
      orders40: [],
    };
  },
  watch: {
    orders: {
      handler(newOrders) {
        this.orders10 = newOrders.get(10);
        this.orders30 = newOrders.get(30);
        this.orders40 = newOrders.get(40);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      status: "auth/status",
    }),
  },
  mounted() {
    this.orders10 = this.orders.get(10);
    this.orders30 = this.orders.get(30);
    this.orders40 = this.orders.get(40);
  },
  methods: {
    ...mapActions({
      changeOrderStatusCall: "orders/changeOrderStatus",
    }),
    getStatus(id) {
      return this.status.find((item) => item.id === id);
    },
    checkMove: function (evt) {
      const draggedItem = evt.item._underlying_vm_ || null;
      const statusId = evt.to.dataset?.statusId || null;
      const payload = {
        order: draggedItem,
        statusId: statusId,
      };
      this.changeOrderStatus(payload);
    },
    changeOrderStatus(payload) {
      this.changeOrderStatusCall({
        order: payload.order,
        id: payload.order.id,
        status_id: Number(payload.statusId),
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.boards-container {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x inline;
  padding: 0 16px 24px;
  min-height: 80vh;
  scroll-padding-inline-start: 12px;
}

.list-group {
  min-height: 100vh;
}

.list-group a {
  margin-bottom: 8px;
}

.boards-container__board {
  flex: 0 0 auto;
  /* width: 100vw; */
  background: #ebecf0;
  border-radius: 3px;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 10px;
  padding: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.2);
  scroll-snap-align: start;
  width: 100%;
}

@media (min-width: 768px) {
  .boards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    grid-gap: 16px;
    padding: 2px;
  }
}
</style>
