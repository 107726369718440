<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service"><a>Zgłoszenia serwisowe</a></router-link>
          <router-link tag="li" :to="{ name: 'ServiceEdit', params: { id: this.$route.params.id }}" class="is-active"><a>Edytuj zgłoszenie serwisowe</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <ServiceForm :id="this.$route.params.id" />
  </section>
</template>

<script>
import { mapActions } from "vuex";

import ServiceForm from "@/views/Service/ServiceForm";
export default {
  name: "ServiceEdit",
  components: {
    ServiceForm,
  },
  methods: {
    ...mapActions({
      getOrderCall: "orders/getOrder",
    }),
    getOrder() {
      this.getOrderCall({
        id: this.$route.params.id,
        setDevice: this.$route.params.setDevice,
      }).catch((error) => {
        this.order = {};
        this.$buefy.toast.open({
          duration: 5000,
          message: error ? error.data.error_description : "",
          position: "is-bottom",
          type: "is-danger",
        });
      });
    },
  },
  created() {
    this.getOrder();
  },
};
</script>

<style lang="scss" scoped></style>
